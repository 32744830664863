<template>
  <div>
      <!--顶部工具条-->
      <el-col :span="24">
        <el-form :inline="true">
          <!-- <el-form-item label="教材名称:">
            <el-input placeholder="请输入关键字" v-model="filters.ApproName"></el-input>
          </el-form-item> -->
          <el-form-item label="教材类型:">
            <el-select v-model="filters.teachType" clearable>
              <el-option
                v-for="item in teachTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用标识:">
            <el-select v-model="filters.selectc" clearable>
              <el-option
              v-for="item in optionsData2"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
      <div>
        <div style="float:left;min-width:400px;max-width:450px;" class="viewsTeachData">
          <el-tree
            :props="props"
            :load="loadNode"
            v-loading="listLoading1"
            show-checkbox
            node-key="ID"
            :data="treeData"
            check-strictly
            ref="tree"
            :check-on-click-node="true"
            :filter-node-method="filterNode"
            @check-change="parentModules"
            lazy
            >
              <span class="custom-tree-node" slot-scope="{ node,data }" style="font-size:16px;">
                <span>
                  <i :class="data.Icon"></i>&nbsp;&nbsp;{{ node.label }}
                </span>              
              </span>
          </el-tree>
        </div>
        <div style="float:left;min-width:55%;max-width:70%;margin-left:10px;">
          <el-table
          :data="tableData"
          border
          v-loading="listLoading1"
          style="">
            <!-- <el-table-column
              prop="QuestionsCode"
              label="试题编号"
              width="180">
            </el-table-column> -->
            <el-table-column
              prop="QuestionsType"
              label="题型"
              width="100">
              <template slot-scope="scope">
                <el-tag
                  :type="''"
                  disable-transitions v-if="scope.row.QuestionsType== 0">{{"单选题"}}
                </el-tag>
                <el-tag
                  :type="'success'"
                  disable-transitions v-if="scope.row.QuestionsType== 1">{{"多选题"}}
                </el-tag>
                <el-tag
                  :type="'warning'"
                  disable-transitions v-if="scope.row.QuestionsType== 2">{{"判断题"}}
                </el-tag>
            </template>
            </el-table-column>
            <!-- <el-table-column  label="范围" width="130" align="center" prop="UseRange">
              <template slot-scope="scope">
                <el-tag :type="'success'" v-if="scope.row.UseRange == 0">字典试题</el-tag>
                <el-tag :type="'warning'" v-if="scope.row.UseRange == 1">公开试题</el-tag>
                <el-tag :type="''" v-if="scope.row.UseRange == 2">私有试题</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="QuestionsTitle"
              label="题目">
            </el-table-column>
          </el-table>
          <!--底部工具条-->
          <el-col :span="12" class="pageBar">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pages.pageIndex"
              :page-sizes="pages.pageArr"
              :page-size="pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pages.dataCount">
            </el-pagination>
          </el-col>
        </div>
      </div>
      <!--新增/编辑界面-->
      <el-dialog
      :title="addOrEdi?'新增':'编辑'"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
      >
      <el-form :model="addForm" label-width="150px" :rules="addFormRules" ref="addForm">
        <el-form-item label="序号：">
          <el-input :value="addForm.questionsCode"  @input="e => addForm.questionsCode = validForbid(e)" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dataId && isView" label="上级：">
          <!-- <el-input v-model="dataIds.Name" disabled @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input> -->
          {{dataIds.Name}}
        </el-form-item>
        <el-form-item label="名称：" prop="processName">
          <el-input :value="addForm.processName"  @input="e => addForm.processName = validForbid(e)" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="数据类型：" prop="dataType">
          <el-select v-model="addForm.dataType" style="width: 300px;">
            <el-option
            v-for="item in teachTypeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试项目：" prop="examProject">
          <el-select v-model="addForm.examProject" style="width: 300px;">
            <el-option
            v-for="item in examProjectArr"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用标识：" prop="IsEnable">
          <el-select v-model="addForm.IsEnable" style="width: 300px;">
            <el-option
            v-for="item in optionsData2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简介：">
          <el-input v-model="addForm.remarks" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--关联试题/取消关联-->
      <el-dialog
      :title="isRelated ? '关联试题' : '取消关联'"
      :visible.sync="relatedQuestion"
      v-model="relatedQuestion"
      width="70%"
      :close-on-click-modal="false"
      >
        <!--顶部工具条-->
        <el-col :span="24">
          <el-form :inline="true">
            <el-form-item label="题型:">
              <el-select v-model="relatedData.selectc">
                <el-option
                v-for="item in optionsData3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input placeholder="请输入题目" v-model="relatedData.ApproName"></el-input>
            </el-form-item>
            <el-form-item label="是否关联:">
              <el-select v-model="relatedData.isRange" clearable>
                <el-option
                v-for="item in optionsData4"
                :key="item.value"
                :label="item.label"
                :disabled="item.disable"
                :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题库:">
              <el-select v-model="relatedData.questionCode" clearable>
                <el-option
                v-for="item in optionsData6"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-table
          :data="tableData1"
          border
          v-loading="listLoading4"
          ref="multipleTable"
          style="">
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- <el-table-column width="60">
            <template scope="scope">
              <el-checkbox v-model="recordPagesData" :label="scope.row.ID" @change="getRow(scope.row)">{{scope.row.ids}}</el-checkbox>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            prop="QuestionsCode"
            label="试题编号"
            width="180">
          </el-table-column> -->
          <el-table-column
            prop="QuestionsType"
            label="题型"
            align="center"
            width="100">
            <template slot-scope="scope">
              <el-tag
                :type="''"
                disable-transitions v-if="scope.row.QuestionsType== 0">{{"单选题"}}
              </el-tag>
              <el-tag
                :type="'success'"
                disable-transitions v-if="scope.row.QuestionsType== 1">{{"多选题"}}
              </el-tag>
              <el-tag
                :type="'warning'"
                disable-transitions v-if="scope.row.QuestionsType== 2">{{"判断题"}}
              </el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column  label="范围" width="130" align="center" prop="UseRange">
            <template slot-scope="scope">
              <el-tag :type="'success'" v-if="scope.row.UseRange == 0">字典试题</el-tag>
              <el-tag :type="'warning'" v-if="scope.row.UseRange == 1">公开试题</el-tag>
              <el-tag :type="''" v-if="scope.row.UseRange == 2">私有试题</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="QuestionsTitle"
            label="题目">
          </el-table-column>
          <el-table-column
            prop="QuestionCode"
            label="题库"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.QuestionCode == 1">A库</span>
              <span v-if="scope.row.QuestionCode == 2">B库</span>
              <span v-if="scope.row.QuestionCode == 3">C库</span>
            </template>
          </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pages1.pageIndex"
            :page-sizes="pages1.pageArr"
            :page-size="pages1.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages1.dataCount">
          </el-pagination>
        </el-col>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="relatedQuestion = false">取消</el-button>
        <el-button type="primary" @click.native="relatedSubmit" :loading="relatedLoading">{{relatedBtn}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTeachingMaterialTree,getExaminationQuestionsByTMIDListPageAsync,getExaminationQuestionsListPage,deleteTeachingMaterialInfo,addTeachingMaterialInfo,updateTeachingMaterialInfo,relationTeachingExamination,delRelationTeachingExamination,getDataDictionaryList } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
  components: { Toolbar },
  inject:['reload'],
  data() {
    return {
      //增删改查按钮
      buttonList: [],
      filters: {
        ApproName: '',
        selectc: true,
        teachType: '',
      },
      listLoading:false,
      listLoading1:false,
      listLoading2:false,
      listLoading4:false,
      optionsData2: [{  //下拉选择
        value: true,
        label: '启用'
      },{
        value: false,
        label: '禁用'
      }],
      teachTypeArr: [
        {value:2,label:'基础'},
        {value:1,label:'岗位'},
        {value:0,label:'行业'},
      ],
      pages: {  //关于分页的obj
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      props: {
        label: 'Name',
      },
      treeData: [],
      tableData: [],
      dataId: '',
      dataIds: '',
      isView: true,
      isFirst: 1,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      _node:'',
      _resolve:'',
      // 新增数据
      addOrEdi: true,
      addFormVisible: false,
      addLoading: false,
      //新增界面数据
      addForm: {
        questionsCode: '',
        processName: '',
        selectType: '',
        IsEnable: true,
        remarks: '',
        dataType: '',
        examProject: '',
      },
      addFormRules: {
        // questionsCode: [{ required: true, message: "请输入编号", trigger: "blur" }],
        processName: [{ required: true, message: "请输入流程名称", trigger: "blur" }],
        IsEnable: [{ required: true, message: "请选择启用标识", trigger: "blur" }],
        selectType: [{ required: true, message: '请选择审批类型', trigger: 'blur' }],
        dataType: [{ required: true, message: '请选择数据类型', trigger: 'blur' }],
        examProject: [{ required: true, message: '请选择考试项目', trigger: 'blur' }],
      },
      // 关联试题
      isRelated: true,
      relatedBtn: "保存",
      relatedQuestion: false,
      relatedLoading: false,
      optionsData3: [{  //下拉选择
        value: 0,
        label: '单选'
      },{
        value: 1,
        label: '多选'
      },{
        value: 2,
        label: '判断'
      }],
      optionsData4: [],
      optionsData6: [{
        value: 1,
        label: 'A库'
      },{
        value: 2,
        label: 'B库'
      },{
        value: 3,
        label: 'C库'
      }],
      examProjectArr: [],
      relatedData: {
        ApproName: '',
        selectc: '',
        isRange: 3,
        questionCode: 1,
      },
      pages1: {  //关于分页的obj
        pageSize: 20,//默认每页条数
        pageArr: [20,40,100,200,500],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      tableData1: [],
      needParentIdArrc: '',
      // 关联试题分页数据
      recordPagesIndex: 1,
      recordPagesData: [],
      canGetApi: 0,
      firstComeIn: true,
    }
  },
  methods: {
    doRefresh() {
      let params = {
        pageIndex: this.pages1.pageIndex,
        pageSize: this.pages1.pageSize,
      };
      if(this.relatedData.selectc !== ''){
        params.questionsType = this.relatedData.selectc
      }
      if(this.relatedData.ApproName !== ''){
        params.questionsTitle = this.relatedData.ApproName
      }
      this.getExaminationlList(params);
    },
    // 增，删，改，查
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    // 查询
    getTeachingMaterialTree() {
      // this.treeData = []
      this.reload();
      this.loadNode(this._node,this._resolve);
      if(this.filters.selectc === true){
        var isSession = 1
        sessionStorage.setItem("isEnable",isSession)
      } else if(this.filters.selectc === false){
        // sessionStorage.removeItem("")
        var isSession = 2
        sessionStorage.setItem("isEnable",isSession)
      } else {
        sessionStorage.setItem("isEnable"," ")
      }
      sessionStorage.setItem("teachType",this.filters.teachType)
      // this.getListPageById();
    },
    // 新增
    handleAdd() {
      // if(this.dataId){
      if(this.needParentIdArrc.length == 10 || this.needParentIdArrc.length > 10){
        this.$message({
          message: '该层级将不能新增！',
          type: 'warning'
        });
        return;
      }
      if(this.dataIds){
        if(this.dataIds.ExaminationQuestiones.length > 0){
          this.$message({
            message: '已关联试题项不能新增！',
            type: 'warning'
          });
          return;
        }
      }
      this.isView = true;
      this.addOrEdi = true;
      this.addFormVisible = true;
      this.addForm = {
        questionsCode: '',
        processName: '',
        selectType: '',
        IsEnable: true,
        remarks: '',
        dataType: '',
        examProject: '',
      }
      // }else{
      //   this.$message({
      //     message: '请选择要所属项！',
      //     type: 'warning'
      //   });
      // }
    },
    // 编辑
    handleEdit() {
      // this.$router.go(0);
      if(this.dataId){
        this.isView = false
        this.addLoading = false;
        this.addForm = {
          questionsCode: this.dataIds.SortNo,
          processName: this.dataIds.Name,
          selectType: '',
          IsEnable: this.dataIds.IsEnable,
          remarks: this.dataIds.Remarks,
          dataType: this.dataIds.DataType,
          examProject: '',
        }
        setTimeout(() => {
          this.addForm.examProject = parseInt(this.dataIds.DataDictionaryId)
        })
        this.addOrEdi = false;
        this.addFormVisible = true;
      }else{
        this.$message({
          message: '请选择要编辑项！',
          type: 'warning'
        });
      }
    },
    // 删除
    handleDel() {
      if(this.dataId){
        var params = {
          ID: this.dataId
        }
        this.$confirm('确认删除吗？', '提示', {}).then(() => {
          deleteTeachingMaterialInfo(params).then(res => {
            var resData = res.data
            if(resData.Success){
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.reload();
            }else{
              this.$message({
                message: resData.Message,
                type: "error"
              });
            }
          })
        })
      }else{
        this.$message({
          message: '请选择要删除项！',
          type: 'warning'
        });
      }
    },
    // 提交
    addSubmit() {
      let _this = this;
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addLoading = true;
          var params = {
            Name: this.addForm.processName,
            DataType: this.addForm.dataType,
            DataDictionaryId: this.addForm.examProject,
            // sortNo: this.addForm.questionsCode || 11,
            IsEnable: this.addForm.IsEnable,
            // remarks: this.addForm.remarks || "按时灯笼裤飞机",
          }
          if(this.addForm.questionsCode){
            params.SortNo = this.addForm.questionsCode
          }
          // if(this.addForm.IsEnable){
          //   params.IsEnable = this.addForm.IsEnable
          // }
          if(this.addForm.remarks){
            params.Remarks = this.addForm.remarks
          }
          if(this.dataId){
            params.ParentId = this.dataId
          }else{
            params.ParentId = 0
          }
          if(this.addOrEdi){
            params.Icon = 'el-icon-s-management'
            addTeachingMaterialInfo(params).then(res => {
              if(res.data.Success){
                var resData = res.data
                this.$message({
                  message: '新增成功',
                  type: 'success'
                });
                this.addFormVisible = false;
                if(!this.dataId){
                  this.reload();
                }else{
                  this.refreshNodeBy(this.dataId);
                }
              }else{
                this.$message({
                  message: resData.Message,
                  type: "error"
                });
              }
            })
            setTimeout(() => {
              _this.addLoading = false;
            },1000)
            if(!this.dataId){
              this.reload();
            }
          }else{
            params.Icon = 'el-icon-s-management'
            params.ID = this.dataId,
            params.ParentId = null
            updateTeachingMaterialInfo(params).then(res => {
              if(res.data.Success){
                var resData = res.data
                this.$message({
                  message: '更新成功',
                  type: 'success'
                });
                this.addFormVisible = false;
                this.refreshNodeBy1(this.dataId);
                this.reload();
              }else{
                this.$message({
                  message: resData.Message,
                  type: "error"
                });
              }
            })
            setTimeout(() => {
              _this.addLoading = false;
            },1000)
          }
        }
      })
    },
    refreshNodeBy(id){
      let node = this.$refs.tree.getNode(id); // 通过节点id找到对应树节点对象
      node.loaded = false;
      node.expand(); // 主动调用展开节点方法，重新查询该节点下的所有子节点
      // node.parent.expand();
    },
    refreshNodeBy1(id){
      let node = this.$refs.tree.getNode(id); // 通过节点id找到对应树节点对象
      node.loaded = false;
      node.expand(); // 主动调用展开节点方法，重新查询该节点下的所有子节点
      node.parent.expand();
    },
    // 根据教材id获取分页列表
    getListPageById(o) {
      var params = {}
      if(o === 1) {
        this.listLoading1 = true
        params = {
          pageIndex: this.pages.pageIndex,
          pageSize: this.pages.pageSize,
        };
        if(this.dataId !== ''){
          params.teachingMaterialId = this.dataId
        }
        if(this.filters.selectc !== ' '){
          params.isEnable = this.filters.selectc
        }
        params.rangeType = 1
      }else if(o === 2) {
        this.listLoading4 = true
        params = {
          pageIndex: this.pages1.pageIndex,
          pageSize: this.pages1.pageSize,
          // dataType: this.filters.teachType,
        };
        if(this.dataId !== ''){
          params.teachingMaterialId = this.dataId
        }
        if(this.relatedData.selectc !== ''){
          params.questionsType = this.relatedData.selectc
        }
        if(this.relatedData.ApproName !== ''){
          params.questionsTitle = this.relatedData.ApproName
        }
        params.rangeType = this.relatedData.isRange || 0
        params.questionCode = this.relatedData.questionCode
      }
      getExaminationQuestionsByTMIDListPageAsync(params).then(res => {
        if(o === 1) {
          this.listLoading1 = false;
          var resData = res.data
          if(resData.Success){
            this.isFirst = 1
            this.tableData = resData.Response.Data
            this.pages.dataCount = resData.Response.DataCount
            this.pages.pageIndex = resData.Response.PageIndex
            if(resData.Response.PageSize > 0){
              this.pages.pageSize = resData.Response.PageSize
            }
          }else{
            this.$message({
              message: resData.Message,
              type: "error"
            });
          }
        }else if(o === 2) {
          var userIds = this.recordPagesData
          var resData = res.data
          if(resData.Success){
            this.tableData1 = resData.Response.Data
            this.pages1.dataCount = resData.Response.DataCount
            this.pages1.pageIndex = resData.Response.PageIndex
            if(resData.Response.PageSize > 0){
              this.pages1.pageSize = resData.Response.PageSize
            }
            // this.$nextTick(() => {
            //   this.tableData1.forEach(row => {
            //     if(userIds.indexOf(row.ID) >= 0){
            //       this.$refs.multipleTable.toggleRowSelection(row,true)
            //     }
            //   })
            // })
            this.listLoading4 = false
          }else{
            this.$message({
              message: resData.Message,
              type: "error"
            });
          }
        }
      });
    },
    // 获取列表
    getExaminationlList(params) {
      // var userIds = this.dataIds.ExaminationQuestiones
      // this.recordPagesData = []
      // this.tableData.forEach(o => {
      //   this.recordPagesData.push(o.ID)
      // })
      var userIds = this.recordPagesData
      getExaminationQuestionsListPage(params).then(res => {
        var resData = res.data
        if(resData.Success){
          this.tableData1 = resData.Response.Data
          this.pages1.dataCount = resData.Response.DataCount
          this.pages1.pageIndex = resData.Response.PageIndex
          if(resData.Response.PageSize > 0){
            this.pages1.pageSize = resData.Response.PageSize
          }
          this.$nextTick(() => {
            this.tableData1.forEach(row => {
              if(userIds.indexOf(row.ID) >= 0){
                this.$refs.multipleTable.toggleRowSelection(row,true)
              }
            })
          })
        }else{
          this.$message({
            message: resData.Message,
            type: "error"
          });
        }
      });
    },
    // 获取考试项目
    getexamProjectData(val) {
      var params = {
        dataType: val
      }
      getDataDictionaryList(params).then(res => {
        var result = res.data
        if(result.Success) {
          this.examProjectArr = result.Response
        }
      })
    },
    handleNodeClick(data) {
    },
    loadNode(node, resolve) {
      var _this = this
      this._node = node
      this._resolve = resolve
      if(node.data){
        var params = {
          parentId: node.data.ID,
        }
      }else{
        var params = {
          parentId: 0,
        }
      }
      if(sessionStorage.getItem("isEnable") == 1){
        params.isEnable = true
      }else if(sessionStorage.getItem("isEnable") == 2){
        params.isEnable = false
      }
      if(sessionStorage.getItem("teachType") && !params.parentId > 0) {
        params.dataType = sessionStorage.getItem("teachType")
      }
      if(this.firstComeIn){
          this.listLoading1 = true
        }
      getTeachingMaterialTree(params).then(res => {
        if(this.firstComeIn){
          this.listLoading1 = false
          this.firstComeIn = false
        }
        var resData = res.data
        if(resData.Success){
          resolve(resData.Response);
        }else{
          _this.$message({
            message: resData.Message,
            type: "error"
          });
        }
      });
    },
    filterNode() {
      console.log("111")
    },
    parentModules(data,checkbox,node) {
      if(this.isFirst == 1){
        var needParentIdArr = []
        var needData = this.$refs.tree.getNode(data)
        if(needData.parent.label){
          needParentIdArr.unshift(needData.parent.key)
          needParentIdArr.push(data.ID)
          var parent1 = needData.parent
          if(parent1.parent.label){
            needParentIdArr.unshift(parent1.parent.key)
            var parent2 = parent1.parent
            if(parent2.parent.label){
              needParentIdArr.unshift(parent2.parent.key)
              var parent3 = parent2.parent
              if(parent3.parent.label){
                needParentIdArr.unshift(parent3.parent.key)
                var parent4 = parent3.parent
                if(parent4.parent.label){
                  needParentIdArr.unshift(parent4.parent.key)
                  var parent5 = parent4.parent
                  if(parent5.parent.label){
                    needParentIdArr.unshift(parent5.parent.key)
                    var parent6 = parent5.parent
                    if(parent6.parent.label){
                      needParentIdArr.unshift(parent6.parent.key)
                      var parent7 = parent6.parent
                      if(parent7.parent.label){
                        needParentIdArr.unshift(parent7.parent.key)
                        var parent8 = parent7.parent
                        if(parent8.parent.label){
                          needParentIdArr.unshift(parent8.parent.key)
                          var parent8 = parent8.parent
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if(needParentIdArr.length > 0){
          this.needParentIdArrc = needParentIdArr
        }else{
          this.needParentIdArrc = [data.ID]
        }
        if(this.dataId == data.ID){
          this.dataId = ''
        }else{
          this.dataId = data.ID
        }
        // this.dataIds = data
        var o = 1
        this.getListPageById(o);
        this.isFirst++

        if(checkbox){
          // 后端返回的node-key不是id，是moduldCode
          this.$refs.tree.setCheckedKeys([data.ID]);
          this.uniqueValue =  this.$refs.tree.getCheckedKeys();
          this.dataIds = data
        }else{
          this.uniqueValue =  this.$refs.tree.getCheckedKeys();
          if(this.uniqueValue.length == 0){
            this.uniqueValue = ''
          }
          this.dataIds = ''
        }
        this.canGetApi = 0
        this.listLoading2 = true
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      var o = 1
      this.getListPageById(o);
    },

    handleSizeChange(val){
      this.pages.pageSize = val
      var o = 1
      this.getListPageById(o);
    },
    // 分页1
    handleCurrentChange1(val) {
      var needTimeArr = this.recordPagesData
      var selectedArr = this.$refs.multipleTable.selection
      this.pages1.pageIndex = val;
      // this.doRefresh();
      var o = 2
      this.getListPageById(o);
      this.recordPagesData = needTimeArr
    },

    handleSizeChange1(val){
      var selectedArr = this.$refs.multipleTable.selection
      var needTimeArr = this.recordPagesData
      this.pages1.pageSize = val
      // this.doRefresh();
      var o = 2
      this.getListPageById(o);
      this.recordPagesData = needTimeArr
    },
    getRow(val) {
      
    },

    // 关联试题相关
    handleRelation() {
      this.recordPagesData = []
      if(this.dataId){
        // return
        if(this.dataIds.ExaminationQuestiones.length > 0){
          this.recordPagesData = this.dataIds.ExaminationQuestiones
        }
        if(!this.dataIds.HasChildren){
          this.optionsData4 = [
            {value: 1,label: '已关联本教材',disable: true},
            {value: 2,label: '未关联本教材',disable: false},
            {value: 3,label: '未关联教材',disable: false}
          ]
          this.isRelated = true
          this.relatedBtn = "保存"
          this.relatedLoading = false;
          this.relatedQuestion = true;
          this.relatedData = {
            ApproName: '',
            selectc: '',
            isRange: 3,
            questionCode: 1,
          }
          // this.doRefresh();
          var o = 2
          this.getListPageById(o)
        }else{
          this.$message({
            message: '该教材有子节点，请在子节点下关联试题！',
            type: 'warning'
          });
        }
      }else{
        this.$message({
          message: '请选择要关联试题项！',
          type: 'warning'
        });
      }
    },
    // 取消关联相关
    handleDelRelation() {
      this.recordPagesData = []
      if(this.dataId){
        // return
        if(this.dataIds.ExaminationQuestiones.length > 0){
          this.recordPagesData = this.dataIds.ExaminationQuestiones
        }
        if(!this.dataIds.HasChildren){
          this.optionsData4 = [
            {value: 1,label: '已关联本教材',disable: false},
            {value: 2,label: '未关联本教材',disable: true},
            {value: 3,label: '未关联教材',disable: true}
          ]
          this.isRelated = false
          this.relatedBtn = "取消关联"
          this.relatedLoading = false;
          this.relatedQuestion = true;
          this.relatedData = {
            ApproName: '',
            selectc: '',
            isRange: 1,
            questionCode: 1,
          }
          // this.doRefresh();
          var o = 2
          this.getListPageById(o)
        }else{
          this.$message({
            message: '该教材有子节点，请在子节点下取消关联！',
            type: 'warning'
          });
        }
      }else{
        this.$message({
          message: '请选择要取消关联项！',
          type: 'warning'
        });
      }
    },
    searchList() {
      // this.doRefresh();
      var o = 2
      this.pages1.pageIndex = 1
      this.getListPageById(o)
    },
    // 保存关联试题
    relatedSubmit() {
      var selectedArr = this.$refs.multipleTable.selection
      var examinationQuestionsIDs = []
      if(selectedArr.length > 0){
        for(var i = 0; i < selectedArr.length; i++){
          examinationQuestionsIDs.push(selectedArr[i].ID)
        }
      // if(this.recordPagesData.length > 0){
      //   var newArr = [...new Set(this.recordPagesData)]
      //   this.recordPagesData = newArr
      // }else{
      //   // this.$message({
      //   //   message: '未选择试题！',
      //   //   type: 'warning'
      //   // });
      //   // return;
      //   var newArr = []
      //   this.recordPagesData = newArr
      }
      var params = {
        // ExaminationQuestionsIDs: this.recordPagesData,
        ExaminationQuestionsIds: examinationQuestionsIDs,
        TeachingMaterialInfoId: this.dataId,
      }
      if(this.needParentIdArrc.length > 0 && this.isRelated){
        params.TreeUrl = ','+this.needParentIdArrc.join(",")+','
      }
      // console.log("params",params)
      if(this.isRelated) {
        this.relatedLoading = true
        this.setrelationTeachingExamination(params)
      } else {
        this.$confirm('已选中'+ examinationQuestionsIDs.length +'条数据，确认取消关联吗？',"提示",{
        }).then(() => {
          this.relatedLoading = true
          this.delrelationTeachingExamination(params)
        })
      }
    },
    // 关联试题接口
    setrelationTeachingExamination(params){
      relationTeachingExamination(params).then((res) => {
        var resData = res.data
        this.relatedLoading = false;
        if(resData.Success){
          this.$message({
            message: '关联成功',
            type: 'success'
          });
          this.relatedQuestion = false;
          // this.reload();
          var o = 1
          this.getListPageById(o);
          this.getTeachingMaterialTree();
        }else{
          this.$message({
            message: resData.Message,
            type: "error"
          });
        }
      })
    },
    // 取消关联接口
    delrelationTeachingExamination(params){
      delRelationTeachingExamination(params).then((res) => {
        var resData = res.data
        this.relatedLoading = false;
        if(resData.Success){
          this.$message({
            message: '取消关联成功',
            type: 'success'
          });
          this.relatedQuestion = false;
          // this.reload();
          var o = 1
          this.getListPageById(o);
          this.getTeachingMaterialTree();
        }else{
          this.$message({
            message: resData.Message,
            type: "error"
          });
        }
      })
    },
    validForbid(value, number = 2550) {
      value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】；‘’，。]/g, '').replace(/\s/g, "");
      if (value.length >= number) {
        this.$message({
          type: "warning",
          message: `输入内容不能超过${number}个字符`
        });
      }
      return value;
    }
  },
  watch: {
  //   'listLoading': function() {
  //     if(!this.listLoading){
  //       this.canGetApi++
  //     }
  //   },
  //   'listLoading1': function() {
  //     if(!this.listLoading1){
  //       this.canGetApi++
  //     }
  //   },
  //   'canGetApi': function() {
  //     if(this.canGetApi == 2) {
  //       this.listLoading2 = false
  //     }
  //   }
    'addForm.dataType': function(val) {
      this.addForm.examProject = ''
      this.getexamProjectData(val)
    }
  },
  mounted() {
    if(sessionStorage.getItem("isEnable") == 1){
      this.filters.selectc = true
    }else if(sessionStorage.getItem("isEnable") == 2){
      this.filters.selectc = false
    }
    if(sessionStorage.getItem("teachType")){
      this.filters.teachType = parseInt(sessionStorage.getItem("teachType"))
    }
    // this.getListPageById();
    let routers = window.localStorage.router
    ? JSON.parse(window.localStorage.router)
    : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  }
}
</script>

<style lang="stylus" scoped>
  .viewsTeachData>>>.el-tree-node__content{
    white-space: pre-wrap!important;
    padding-top: 3px!important;
    padding-bottom: 3px!important;
  }
  .pageBar{
	  margin-top:20px;
	  margin-bottom :20px
  }
</style>